<template>
  <!-- <div :class="pageType === 'readerv2' || isMobileFunction() ? 'w-100' : ''"> -->
  <!-- <div v-if="pratilipi"> -->
  <div style="    width: 93%;">
    <div class="nextepisode center-block row">
      <div class="col-md-3"></div>
      <span
        class="image"
        style="
    border-radius: 4px;
    height: 200px;
    width: 187px;
        margin-left: 30px; 
"
        ><img
          :src="coverImage"
          alt="..."
          class="image"
          style="border-radius: 4px;height: 150px;
    width: 150px;;/* margin-right: 90px; */"
        />
      </span>

      <div class="text">
        <div
          style="
    color: white;
    text-align: initial;
    width: 64px;
    height: 19px;
    /* margin: 0 151px 40px 0; */
    font-family: NotoSansDevanagari;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #adadad;
    margin-top: 50px;
"
        >
          एपिसोड्स-{{this.pratilipiNextPart}}
        </div>
        <div
          style="
    color: white;
    text-align: initial;
    width: 212px;
    /* height: 24px; */
    /* margin: 86px 88px 102px 16px; */
    font-family: NotoSans;
    font-size: 18px;
  
    font-stretch: normal;
    font-style: normal;
    /* line-height: normal; */
    letter-spacing: normal;
    color: #fefefe;
"
        >
          {{ pratilipiTitle }}
        </div>
        <router-link
          
          :to="{
            name: 'home-episode',
            params: {
              pratilipiId: nextPratilipiId,
            },
          }"
          ><button
            type="button"
            class="btn btn-warning button"
            style="
    width: 255px;
    height: 35px;
    margin-top: 19px;
    font-weight: 900;
    "
          >
            अगला भाग पढ़ें
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import inViewport from "vue-in-viewport-mixin";
import { mapGetters, mapActions } from "vuex";
// import PratilipiImage from "@/components/RecommendationImage";
import {
  triggerAnanlyticsEvent,
  isMobile,
  getDaysLeftFromTime,
  openLoginModal,
} from "@/mixins/methods";
// sost AuthorSubscribeModal = () =>
//   import(
//     /* webpackChunkName: "AuthorSubscribeModal" */ "@/components/AuthorSubscribeModal.vue"
//   );

export default {
  name: "NextPratilipiStrip",
  mixins: [inViewport],
  components: {},
  data() {
    return {
pratilipiNextPart:null
    };
  },
  props: {
    pratilipiTitle: {
      type: String,
    },
    
    pratilipiPart: {
      type: String,
    },
    coverImage: {
      type: String,
    },
    nextPratilipiId: {
      type: Number,
    },
  },
  computed: {},
  created() {
    this.pratilipiNextPart=parseInt(this.pratilipiPart) + 1;
    
    
  },
  methods: {
    ...mapActions("pratilipi", ["setDownloadAppLocation"]),
    isMobileFunction() {
      return isMobile();
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  @media screen and (max-width: 768px) {
    margin-left: 30px;
  }
}
.nextepisode {
  margin-top: 50px;
  margin-left: 89px;
  @media screen and (max-width: 768px) {
    margin-top: 0px !important;
    margin-left: 0px !important;
        margin-bottom: 10%;
  }
}
.image {
  @media screen and (max-width: 768px) {
    width: 100px !important;
    height: 100px !important;
    margin-left: 20px !important;
    margin-top: 25px !important;
  }
}

$superfan-blue: #056974;
$white: #fff;
.pratilipi {
  @media screen and (max-width: 768px) {
    .recommendation-cover-image {
      width: auto;
    }
  }
  .recommendation-cover-image {
    height: 80px !important;
  }
  .summary-section {
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    .pratilipi-container {
      flex-wrap: nowrap;
    }
  }
  .name {
    font-size: 12px;
    color: #666666;
    line-height: 1.5;
  }
  .rating-value {
    margin-top: 5px !important;
  }
  .summary {
    clear: both;
    text-align: left;
    max-height: 200px;
    overflow: hidden;
    font-size: 15px;
    margin: 5px 0 1px;
    color: #666666;
    max-height: 65px;
    @media screen and (max-width: 768px) {
      max-height: 65px;
      height: auto;
    }
  }
  .title {
    text-align: left;
    font-size: 18px;
    color: #212121;
    line-height: 1.56;
  }
  .rating {
    border: none;
    i {
      color: #056974;
      font-size: 12px;
    }
  }
  .read-complete-story {
    color: white;
    height: 36px;
    font-size: 14px;
    display: block;
    font-weight: bold;
    display: inline-block;
    margin-top: 8 px;
    width: 100%;
    margin-top: 8px;
    justify-content: center;
    border-radius: 3%;
    line-height: 1.5;
    font-size: 17px;
    text-align: center;
    background: #d32f2f;
    color: #fff;
    font-weight: bold;
    text-align: center;
    border: 0;
    outline: none;
    padding: 0;
    cursor: pointer;
  }
  .subscribe-container {
    background: linear-gradient(rgba(5, 105, 116, 1), rgba(32, 185, 202, 0.8)),
      url(https://static-assets.pratilipi.com/pratilipi-logo/subscription-cta.svg);
    color: #fff;
    border-radius: 4px;
    .free-date {
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: #858585;
      background: #fff;
      border-radius: 2px;
      padding: 10px 0px;
      width: 100%;
      .days {
        color: #858585;
        padding-left: 3px;
      }
    }
    .subscribe-description {
      font-size: 0.875rem;
      line-height: 1.125rem;
      .learn-more {
        color: #fff;
      }
    }
    .subscribe-cta {
      background-color: $white;
      color: $superfan-blue;
      font-size: 0.875rem;
      float: left;
      border-radius: 4px;
      width: 260px;
      align-self: center;
    }
  }
  @media screen and (max-width: 768px) {
    .read-complete-story {
      margin-bottom: 28px;
    }
  }
  .img {
    @media screen and (max-width: 641px) {
      margin-top: 50px;
    }
  }
  .col-md-3 {
    border: 1px solid #ccc !important;
    padding: 10px !important;
    margin: 10px !important;
    min-height: 300px !important;
    box-shadow: 10px 10px 5px #888888 !important;
  }
}
.button{
@media screen and (max-width: 641px) {
    width: 195px !important;
    height: 35px !important;
    /* margin-top: 19px; */
    font-weight: 900 !important;
  }
}
.span{
  @media screen and (max-width: 641px) {}

}
.image{
  @media screen and (max-width: 641px) {
    margin-left: 2% !important;}
}
</style>