<template>
  <div style="background-color: #050b06">
    <div v-if="getPratilipiLoadingState === 'LOADING'" class="spinner-overlay">
      <Spinner />
    </div>
    <div>
      <Header class="shadow" />

      <div v-for="(images, index) in chapters" :key="index">
        {{ setEpisodeNumber(index) }}

        <div v-if="firstEpisodeToShow + index + 1 <= 6" class="left">
          <span class="title"
            >#{{ firstEpisodeToShow + index + 1 }} एपिसोड {{ getPartNumber }}
            {{ getPratilipiTitle }}</span
          >
          <span>
            <button @click="$router.go(-1)" class="button">
              <i
                class="arrow left"
                style="
                  border-width: 0px 3px 3px 0px;
                  border-style: solid;
                  border-color: white;
                  border-image: initial;
                  display: inline-block;
                  padding: 3px;
                  transform: rotate(135deg);
                "
              >
              </i>
              Back to home
            </button>
          </span>
          <span class="episode-number">
            एपिसोड {{ firstEpisodeToShow + index + 1 }}</span
          >

          <span class="highlight"> {{ getPratilipiTitle }}</span>
        </div>
        <div v-if="firstEpisodeToShow + index + 1 <= 6" class="container read">
          <div
            class="col-md-offset-1 col-md-11 col-sm-2 col-xs-12 reader-section"
          >
            <div
              id="carousel-pager"
              class="carousel slide"
              data-ride="carousel"
              data-interval="1000"
            >
              <div class="carousel-inner vertical">
                <div class="item" style="object-fit: fill">
                  <div
                    v-if="getPratilipiLoadingState === 'LOADING_SUCCESS'"
                    v-for="(image, index) in images"
                    :key="index"
                    class="section"
                    style="color: #fff"
                  >
                    <img
                      :src="image"
                      class="img-responsive Episodeimage"
                      data-target="#carousel-main"
                      data-slide-to="0"
                      width:100
                    />
                  </div>
                  <div
                    v-if="images.length"
                    v-observe-visibility="handleScrolledToBottom"
                  ></div>
                </div>
              </div>
              <a
                class="left carousel-control"
                href="#carousel-pager"
                role="button"
                data-slide="prev"
              >
                <span
                  class="glyphicon glyphicon-chevron-up"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="right carousel-control"
                href="#carousel-pager"
                role="button"
                data-slide="next"
              >
                <span
                  class="glyphicon glyphicon-chevron-down"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NextPratilipiStrip
      v-if="
        getPratilipiLoadingState === 'LOADING_SUCCESS' && getNextPratilipiData
      "
      :pratilipiTitle="getPratilipiTitle"
      :pratilipiPart="getPartNumber"
      :coverImage="getCoverImage"
      :nextPratilipiId="getNextPratilipiData"
    />
    <a
      @click="triggerClickDownloadEvent"
      href="https://get.pratilipicomics.com/4u9FS2EWJgb"
      style="background-color: #050b06; width: 100%"
      class="row h-100 h-100"
    >
      <img
        v-if="getPratilipiLoadingState === 'LOADING_SUCCESS'"
        class="banner-image"
        style="max-width: 100%"
        src="https://s3.ap-south-1.amazonaws.com/pratilipi.comic.assets/website/img/download-app-banner-hindi.jpg"
      />
    </a>
  </div>
</template>

<script>
import Header from "../components/HeaderV2";

import { mapActions, mapGetters } from "vuex";
import AppBannerTop from "@/components/AppBannerTop.vue";

import NextPratilipiStrip from "@/components/NextPratilipiStrip.vue";
import Spinner from "@/components/Spinner.vue";
import constants from "@/constants";
import mixins from "@/mixins";

export default {
  components: {
    NextPratilipiStrip,
    Header,
    Spinner,
  },

  mixins: [mixins],
  data() {
    return {
      getAuthorData: null,
      getContentData: null,
      pratilipiIds: [],
      images: [],
      episode: 0,
      chapters: [],
      episodeNo: 0,
      lastEpisode: 0,
      firstEpisodeToShow: 0,
      seriesId:0,
      seriesName:0,
    };
  },

  computed: {
    ...mapGetters("pratilipipage", [
      "getPratilipiData",
      "getPratilipiTitle",
      "getPartNumber",
      "getNextPratilipiData",
      "getNextPratilipiLoadingState",
      "getCoverImage",
      "getPratilipiLoadingState",
      "getPratilipiIds",
      "getPratilipisSeriesData"

    ]),
  },

  created() {
    if (!this.getPratilipiIds.indexOf) {
      this.$router.go(-1);
    }
    this.seriesId = this.getPratilipisSeriesData.seriesId;
    this.seriesName = this.getPratilipisSeriesData.title;
    if(this.getPratilipiIds.indexOf){
    this.firstEpisodeToShow = this.getPratilipiIds.indexOf(
      this.$route.params.pratilipiId
    )
    this.episode = this.firstEpisodeToShow;
    this.fetchPratilipiDetails(this.getPratilipiIds[this.episode]);

    if (this.getPratilipiIds.length > 0) {
      this.pratilipiIds = this.getPratilipiIds;
      this.lastEpisode = this.pratilipiIds.length;
    }
    this.pratilipiId = this.$route.params.pratilipiId;

    this.triggerAnanlyticsEvent(`LANDED_EPISODE_PAGE`, "CONTROL", {
      PRATILIPIID: this.$route.params.pratilipiId,
      LANGUAGE:"HINDI",
      SCREEN_NAME: "ReaderPage",
      SERIES_ID: this.seriesId,
      SERIES_NAME: this.seriesName,
    });
  }
  },
  watch: {
    getPratilipiData(state) {
      this.chapters.push(
        this.getPratilipiData.map((data) => {
          return (
            "https://static-assets.pratilipi.com/pratilipi/content/image?pratilipiId=" +
            this.getPratilipiIds[this.episode] +
            "&name=" +
            data.data.name +
            "&width=600"
          );
        })
      );
    },
  },

  methods: {
    ...mapActions(["setShareDetails"]),

    ...mapActions("pratilipipage", [
      "fetchPratilipiDetails",
      "fetchSeriesChapters",
      "fetchMoreSeriesChapters",
      "addSeriesToLibrary",
      "removeSeriesFromLibrary",
      "resetShowPratilipiRemoveError",
    ]),
    setEpisodeNumber(index) {
      this.episodeNo = index + 1;
    },
    hideStripAndRedirect() {
      // this.isNextPratilipiEnabled = false;
      // this._triggerReaderAnalyticsEvent("GONEXTPRATILIPI_BOOKEND_READER");
      // this.$router.push({
      //     path: this.makeReadUrl(this.getPratilipiData.nextPratilipi.readPageUrl)
      // });
    },
    handleScrolledToBottom(isvisible) {
      if (!isvisible) {
        return;
      }
      
  
      if (this.episode < this.lastEpisode - 1) {
        if(this.episode <= 5){
        this.triggerCompleteReadEvent(this.episode)
         }
 
        this.episode++;

        // this.episode++;
        if (this.getPratilipiIds[this.episode]) {
          this.fetchPratilipiDetails(this.getPratilipiIds[this.episode]);
        }
      }

      
    },
    // triggerCompleteReadEvent(){
    //   this.triggerAnanlyticsEvent(`CLICK_DOWNLOAD_APP`, "CONTROL", {
    //     LOCATION: "EpisodePage",
    //     utm_source: this.getUrlVars()["utm_source"],
    //     utm_medium: this.getUrlVars()["utm_medium"],
    //     utm_campaign: this.getUrlVars()["utm_campaign"],
    //     SCREEN_NAME: "ReaderPage",
    //     LANGUAGE:"HINDI",
    //   });
    // },
    triggerCompleteReadEvent(episodeNo){
this.triggerAnanlyticsEvent(`READER_ACTION`, "CONTROL", {
        SERIES_ID: this.seriesId,
        SERIES_NAME: this.seriesName,
        SCREEN_NAME: "ReaderPage",
        LANGUAGE:"HINDI",
        PRATILIPI_ID:this.getPratilipiIds[episodeNo],
        UI_POSITION: episodeNo+1
      });
    },
    triggerClickDownloadEvent() {
      this.triggerAnanlyticsEvent(`CLICK_DOWNLOAD_APP`, "CONTROL", {
        LOCATION: "header",
        SCREEN_NAME: "ReaderPage",
        LANGUAGE:"HINDI",
        SERIES_ID: this.seriesId,
        SERIES_NAME: this.seriesName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.left {
  background-color: #0b0b0b;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}
.Episodeimage {
  @media screen and (max-width: 641px) {
    width:100%;
  }
}
.box {
  margin-bottom: 8px;
  display: flex;
}
.episode-number {
  width: 50px;
  height: 19px;
  margin: 5px 10px 0 0;
  font-family: NotoSansDevanagari;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #adadad;
  margin-left: 134px;
  @media screen and (max-width: 641px) {
    visibility: hidden;
  }
}
.highlight {
  @media screen and (min-width: 641px) {
    font-family: NotoSans;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fefefe;
  }
}
.left {
  flex: 1;
  text-align: initial;
  @media screen and (max-width: 641px) {
    padding-top: 16px;
    height: 57px;
  }
}

.right {
  flex: 0 0 620px;
  text-align: left;
  color: white;
}
.button {
  @media screen and (min-width: 641px) {
    color: rgb(254, 254, 254);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 80px;
    letter-spacing: normal;
    background-color: transparent;
    outline: none;
    border: none;
    align-content: space-around;
    margin-left: 370px;
  }
  @media screen and (max-width: 641px) {
    display: none;
  }
}

.title {
  width: 211.8px;
  height: 22px;
  margin: 0px 92.2px 0px 16px;
  font-family: NotoSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fefefe;
  @media screen and (min-width: 641px) {
    display: none;
  }
}
.arrow {
  @media screen and (max-width: 641px) {
    display: none;
  }
}
.next-strip-container {
  pointer-events: auto !important;
  margin: 0 auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 520px;
  cursor: pointer;
  overflow: hidden;
}
.reader-section {
  padding: 0px;
  margin: 0px;
}
.read {
  padding: 0px;
}
.banner-image {
  margin-left: 20% !important;
  margin-top: 4% !important;
  @media screen and (max-width: 641px) {
    margin-left: 4% !important;
    margin-top: 7% !important;
  }
}
</style>
