var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-wrap"},[_c('div',{staticClass:"cube"},_vm._l((_vm.cubeSidesToShow),function(eachSide,index){return _c('div',{key:eachSide.language,staticClass:"face cell",class:{
        'right-side': eachSide.frontFacing === true,
        'front-side': index === 1,
        'back-side': index === 2,
        'top-side': index === 3,
        'bottom-side': index === 4,
        'left-side': index === 5,
      }},[_c('span',[_vm._v(_vm._s(eachSide.text))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }