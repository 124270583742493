import { render, staticRenderFns } from "./NextPratilipiStrip.vue?vue&type=template&id=52a0cbee&scoped=true&"
import script from "./NextPratilipiStrip.vue?vue&type=script&lang=js&"
export * from "./NextPratilipiStrip.vue?vue&type=script&lang=js&"
import style0 from "./NextPratilipiStrip.vue?vue&type=style&index=0&id=52a0cbee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a0cbee",
  null
  
)

export default component.exports