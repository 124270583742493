<template>
  <div
    class="navigation"
    style="background-image: linear-gradient(to bottom, #313c33, #161c17); width: 100; height:100;"
  >
    <nav class="navbar navbar-light  ">
      <div class="container-fluid">
        <span class="navbar-brand"   @click="triggerHomeEvent">
          <img
            src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/comics-logo-copy-04.png"
            class="logo"
          />
        </span>

        <form class="d-flex">
          <button
            @click="triggerCreateEvent"
            type="button"
            class="btn btn-dark"
          >
            upload comics <span class="right-arrow">&gt;</span>
          </button>
        </form>
      </div>
    </nav>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  methods: {
    triggerCreateEvent() {
      //this.$router.push("/login");
      location.href='/home'

      this.triggerAnanlyticsEvent(`CLICK_PUBLISH`, "CONTROL", {
        LOCATION: "header",
      });
      return true;
    },

    triggerHomeEvent() {
      this.$router.push("/");
    },
  },
};
</script>

<style type="text/css">
.brand-text {
  font-size: 14px;
  background-image: linear-gradient(to bottom, #313c33, #161c17);
}

.navbar-brand > .logo {
  background-image: linear-gradient(to bottom, #313c33, #161c17);
  margin-left: 10px;
  max-height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
}

.mobile-navbar-title {
  max-width: 60px;
  max-height: 24px;
  white-space: nowrap;
  overflow: hidden;
}
.right-arrow {
  width: 112px;
  height: 23px;
  margin: 0 9px 0 0;
  font-family: KohinoorDevanagari;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fefefe;
}
</style>
