<template>
    <div class="app-banner">
        <button
            class="close_button"
            @click="closeBanner"><i class="pratilipi-icons">&#xe801;</i></button>
        <div class="logo">
            <img
                src="../assets/pratilipi_logo.png"
                alt="logo">
        </div>
        <div class="app-info">
            <span class="name">Pratilipi</span>
            <div class="app-desc">Read 1,000,000+ stories for FREE</div>
        </div>
        <button
            class="download-now"
            @click="downloadApp">Read In App</button>
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        mixins: [
            mixins
        ],
        props: {
            screenName: {
                type: String,
                required: true
            }
        },
        data () {
            return {
                appBanner_clicked: this.getCookie('appBannerTop_clicked') == null ? 0 : parseInt(this.getCookie('appBannerTop_clicked') || 0)
            };
        },
        watch: {
            'appBanner_clicked': function () {
                this.execNewCookieLogic();
            }
        },
        mounted () {
            setTimeout(() => {
                if ($('.app-banner').is(':visible') === true) {
                    if (this.screenName === 'READER') {
                        $('#app .header-section').css('top', '60px');
                        $('.read-page .book-content').css('margin-top', '110px');
                    }
                    this.triggerAnanlyticsEvent(`VIEWED_APPBANNER_${this.screenName}`, 'CONTROL', {});
                }
            }, 500);
        },
        methods: {
            closeBanner () {
                this.triggerAnanlyticsEvent(`CLOSED_APPBANNER_${this.screenName}`, 'CONTROL', {});

                $('.app-banner').slideUp();
                if (this.screenName === 'READER') {
                    $('#app .header-section').css('top', '0px');
                    $('.read-page .book-content').css('margin-top', '49px');
                }
                this.appBanner_clicked++;
            },
            downloadApp () {
                this.triggerAnanlyticsEvent(`CLICKED_APPBANNER_${this.screenName}`, 'CONTROL', {});
                const playstoreUrl = 'https://play.google.com/store/apps/details?id=com.pratilipi.mobile.android&referrer=utm_source=' + this.screenName.toLowerCase() + '&utm_medium=' + this.screenName.toLowerCase() + '&utm_campaign=app_download';
                this.openInNewTab(playstoreUrl);
            },
            execNewCookieLogic () {
                if (this.appBanner_clicked === 1) { this.setCookie('appBannerTop_viewed', false, 3, '/'); }
                if (this.appBanner_clicked === 2) { this.setCookie('appBannerTop_viewed', false, 7, '/'); }
                if (this.appBanner_clicked === 3) { this.setCookie('appBannerTop_viewed', false, 30, '/'); }
                if (this.appBanner_clicked >= 4) { this.setCookie('appBannerTop_viewed', false, 365, '/'); }

                this.setCookie('appBannerTop_clicked', this.appBanner_clicked, 365, '/');
            }
        }
    };
</script>

<style lang="scss" scoped>
.app-banner {
    background: #f0f0ee;
    z-index: 15;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px 0;
    text-align: left;
    font-size: 14px;
    .close_button {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        padding: 0 3px;
        text-align: center;
        border: 0;
        outline: none;
        background: none;
        i {
            font-size: 17px;
            vertical-align: middle;
        }
    }
    .logo {
        width: 40px;
        height: 40px;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        img {
            max-width: 100%;
        }
    }
    .app-info {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 180px);
        margin: 0 4px;
        font-size: 13px;
        .name {
            font-weight: bold;
        }
        .app-desc {
            font-size: 11px;
        }
    }
    .download-now {
        display: inline-block;
        vertical-align: middle;
        background: #03a87c;
        border-radius: 5px;
        border: 1px solid;
        color: #fff;
        padding: 10px 7px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        margin: 0;
        cursor: pointer;
        outline: none;
    }
}
</style>
